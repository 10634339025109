import { z } from "zod";

const programConfigSchema = z.object({
  updates: z.string(),
  programRefresh: z.string(),
  payoutColumn: z.string().optional(),
  programDescription: z.string(),
  thumbnailUri: z.string().nullable(),
  testMode: z.boolean().default(false),
  name: z.string(),
  leaderboardSql: z.string().optional(),
  jsonTags: z
    .object({
      infoPage: z.string().optional(),
      cardDescription: z.string().optional(),
    })
    .default({}),
  startsAt: z.coerce.date().optional(),
  endsAt: z.coerce.date().optional(),
});

const programStateSchema = z.object({
  participants: z.number(),
  totalRedeemed: z.number(),
  activeParticipants: z.number().optional(),
});

const programStateGenesisSchema = z.object({
  participants: z.number(),
  totalRedeemed: z.number(),
});

export const schema = z.object({
  id: z.string(),
  programConfig: programConfigSchema,
  programState: programStateSchema,
  programEpoch: z.number(),
  programStateGenesis: programStateGenesisSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  nextRefresh: z.coerce.date(),
  lastRefresh: z.coerce.date(),
});

export type Program = z.infer<typeof schema>;
